<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>
        Documents 
      </b-card-title>
      <b-card-text class="mr-25 mb-0">
        <!-- View All -->
        <b-spinner v-if="!fileTypes" small variant="primary" />
        <span v-else @click.self="viewAll">View All</span>
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in fileTypes"
          :key="item.id"
          md="2"
          sm="2"
          class="text-center"
        >
          <b-avatar
            button
            rounded="sm"
            class="mb-1 mt-1"
            :variant="item.color"
            size="48"
            @click="showDoc(item)"
          >
            <feather-icon
              size="21"
              :icon="item.icon"
            />
          </b-avatar>
          <b-media-body class="my-auto">
            <b-card-text class="font-small-3 mb-0">
              {{ item.subtitle }}
            </b-card-text>
          </b-media-body>
        </b-col>
        <b-col
          v-if="index > 5"
          md="2"
          sm="2"
          class="text-center"
        >
          <b-avatar
            button
            rounded="sm"
            class="mb-1 mt-1"
            variant="secondary"
            size="48"
            @click="viewAll"
          >
            <span>+{{index-5}}</span>
          </b-avatar>
        </b-col>
      </b-row>
    </b-card-body>
    <b-modal
      :id="'modal-detail-doc'"
      centered
      title="Daftar Detail Dokumen"
      hide-footer
      size="lg"
    >
      <base-table
        v-if="dataDetailUrl"
        ref="basetable"
        :dataurl="dataDetailUrl"
        :fields="fieldDetails"
        :filter="filter"
        :is-edit="false"
        :is-delete="false"
        :is-add="false"
        :is-view="false"
      >

      <template #cell(path)="data">
        <b-avatar
            button
            rounded="sm"
            :variant="'primary'"
            size="30"
            @click="download(data.item.path)"
          >
            <feather-icon
              size="15"
              icon="FileIcon"
            />
          </b-avatar>
      </template>
      
      </base-table>
    </b-modal>
    <b-modal
      :id="'modal-doc-all'"
      centered
      title="Daftar Dokumen"
      hide-footer
      size="lg"
    >
      <base-table
        ref="basetable"
        :dataurl="dataUrl"
        :fields="fields"
        :is-edit="false"
        :is-delete="false"
        :is-add="false"
      >

      <template #cell(_)="data">
        <b-avatar
            button
            rounded="sm"
            :variant="'primary'"
            size="30"
            @click="showDoc(data.item)"
          >
            <feather-icon
              size="15"
              icon="FolderIcon"
            />
          </b-avatar>
      </template>

      </base-table>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, 
  BMediaAside, BAvatar, BMediaBody, BSpinner, BModal
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import BaseTable from '@/views/base/BaseTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    StatisticCardVertical,
    BSpinner,
    BModal,
    BaseTable
  },
  props:{
    filter: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      dataUrl:'file-type?filter[type]=spb',
      dataDetailUrl:null,
      fileTypes: null,
      colors:[ // light-
        'primary',
        'success',
        'info',
        'warning',
        'danger',
      ],
      index:null,
      fileTypeAll:null,
      fields:[
        {key:'name', label: 'Tipe Dokumen', sortable: true}
      ],
      fieldDetails:[
        {key:'name', label: 'Tipe Dokumen', sortable: true},
        {key:'note', label: 'Keterangan', sortable: true},
        {key:'path', label: 'File', sortable: true}
      ]
    }
  },
  mounted(){
    this.fetchData()
  },
  methods:{
    fetchData(){
      this.$http.get(this.dataUrl).then(res=>{
        this.fileTypes = []
        if(res.data.data){
          this.fileTypeAll = res.data.data
          this.index = res.data.data.length
          res.data.data.forEach((value, index ) => {
            if(index < 5)
              this.fileTypes.push(
                {
                  id:value.id,
                  icon: 'FileIcon',
                  color: this.colors[Math.floor(Math.random()*this.colors.length)],
                  subtitle: value.name,
                }
              )
          })
        }
      })
    },
    showDoc(item){
      this.dataDetailUrl = 'file-type/showAll/'+item.id
      this.$bvModal.show('modal-detail-doc')
    },
    viewAll(){
      this.$bvModal.show('modal-doc-all')
    },
    download(path){
      window.open(path)
    }
  }
}
</script>
