<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card>
        <b-col cols="9">
          <h3 class="font-weight-bolder">
            Unit
          </h3>
          <br>
          
          <form-v-select
            ref="region"
            v-model="region"
            dataurl="region"
            label="Region"
            placeholder="Semua Region"
            :item_text="'name'"
          />

          <b-form-group
            label-cols-md="4"
            label="Periode"
          >
            <b-input-group>
              <flat-pickr
                v-model="dateStart"
                :config="optionFlatPicker"
                class="form-control"
                placeholder="Tanggal Awal"
              />
              <b-input-group-append is-text>
                To
              </b-input-group-append>
              <flat-pickr
                v-model="dateEnd"
                :config="optionFlatPicker"
                class="form-control"
                placeholder="Tanggal Akhir"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <b-form-group
            label-cols-md="4"
            label=""
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click="show"
            >Show</b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              type="button"
              @click="reset"
            >Reset</b-button>
          </b-form-group>
        </b-col>
      </b-card>
    </b-col>
    <b-col
      lg="12"
    >
      <card-document v-if="loadFilter" :filter="filter" />
    </b-col>
    <b-col
      lg="12"
    >
      <card-tracking-bill v-if="loadFilter" :filter="filter" />
    </b-col>
    <b-col lg="12">
      <card-vendor v-if="loadFilter" :filter="{'filter[branch_id]':region?region.id:null}" />
    </b-col>
    <b-col
      lg="12"
    >
      <card-skko />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BButton, BFormGroup, BInputGroupAppend, BInputGroup } from 'bootstrap-vue'
import CardDocument from './include/CardDocument.vue'
import CardTrackingBill from './include/CardTrackingBill.vue'
import CardVendor from './include/CardVendor.vue'
import CardSkko from './include/CardSkko.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import FlatPickr from 'vue-flatpickr-component'
require("flatpickr/dist/flatpickr.css")
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
require('flatpickr/dist/plugins/monthSelect/style.css')
import moment from 'moment'

export default {
  components: {
    BRow, BCol, BCard, BButton, BFormGroup, BInputGroupAppend, BInputGroup,
    CardDocument,
    CardTrackingBill,
    CardVendor,
    CardSkko,
    FormVSelect,
    FlatPickr
  },
  data(){
    return {
      region:null,
      dateStart:moment().startOf('month').format('YYYY-MM-DD'),
      dateEnd:moment().format('YYYY-MM-DD'),
      loadFilter:true,
      filter:[],
      optionFlatPicker:{
        static: true,
        altInput: true,
        plugins: [new monthSelectPlugin({shorthand: false, dateFormat: "Y-m-d", altFormat: "F Y"})]
      }
    }
  },
  mounted(){
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      if(info.contact.region){
        this.region = info.contact.region
      }
    }
    this.filter['filter[branch_id]'] = this.region?this.region.id:null
    this.filter['between[periode]'] = this.dateStart+","+this.dateEnd
    this.show()
    // this.getData()
  },
  methods:{
    show(){
      this.filter['filter[branch_id]'] = this.region?this.region.id:null
      this.filter['between[periode]'] = this.dateStart+","+moment(moment(this.dateEnd).format('Y-M')).endOf('month').format('Y-MM-D')
      this.loadFilter = false
      this.$nextTick().then(() => {
        this.loadFilter = true
      })
    },
    reset(){
      if(this.$store.state.profile.info){
        var info = this.$store.state.profile.info
        if(info.contact.region){
          this.region = info.contact.region
        }
      }
      this.show()
    }
  }
}
</script>

<style lang="scss" scoped>
  
</style>