<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        Tracking Tagihan 
      </b-card-title>
      <b-card-text class="mr-25 mb-0">
        <!-- View All -->
        <!-- <b-spinner v-if="!fileTypes" small variant="primary" /> -->
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body text-center" style="align-self: center;padding: 0px;justify-content: center;">
      <b-row>
        <b-col cols="12">
          <b-tabs justified>
            <b-tab active>
              <template #title>
                Vendor
              </template>
              <tracking-bill :filter="filter" :index="'submitted'" />
            </b-tab>
            <b-tab>
              <template #title>
                Region
              </template>
              <tracking-bill :filter="filter" :index="'approved'" />
            </b-tab>
            <b-tab>
              <template #title>
                Operasi
              </template>
              <tracking-bill :filter="filter" :index="'checkOperasional'" />
            </b-tab>
            <b-tab>
              <template #title>
                Akutansi
              </template>
              <tracking-bill :filter="filter" :index="'checkAkuntansi'" />
            </b-tab>
            <b-tab>
              <template #title>
                Keuangan
              </template>
              <tracking-bill :filter="filter" :index="'checkKeuangan'" />
            </b-tab>
            <b-tab>
              <template #title>
                Pembayaran
              </template>
              <tracking-bill :filter="filter" :index="'paid'" />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, 
  BCardText, BCardBody, BRow, 
  BCol, BMedia, BMediaAside, 
  BAvatar, BMediaBody, BSpinner,
  BTabs, BTab
} from 'bootstrap-vue'
import TrackingBill from './IndexTrackingBill.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BSpinner,
    BTabs,
    BTab,
    TrackingBill
  },
  props:{
    filter: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
</script>
