<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>
        Vendor 
      </b-card-title>
      <b-card-text class="mr-25 mb-0">
        <!-- View All -->
        <b-spinner v-if="!spb" small variant="primary" />
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <base-table
        ref="basetable"
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :is-edit="false"
        :is-delete="false"
        :is-add="false"
        :pageSize="5"
        :filter="filter"
        @showData="spb=true"
      >
        <template #cell(nominal)="data">
          Rp {{getNominal(data.item)|numFormat}}
        </template>
        <template #cell(index)="data">
          {{getBillCount(data.item)}}
        </template>
        <template #cell(month_index)="data">
          {{getLastMonthBill(data.item)}}
        </template>
        <template #cell(total)="data">
          {{getTotalTagihan(data.item)}}
        </template>
        <template #cell(billed)="data">
          {{getStatus(data.item)}}
        </template>
        <template #cell(_)="data">
          <b-button
            size="sm"
            variant="primary"
            class="mr-1 btn-icon"
            @click="viewForm(data)"
          >
            <feather-icon icon="FolderIcon" />
          </b-button>
        </template>

      </base-table>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, 
  BMediaAside, BAvatar, BMediaBody, BSpinner, BButton
} from 'bootstrap-vue'
import BaseTable from '@/views/base/BaseTable.vue'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BSpinner,
    BButton,
    BaseTable
  },
  props:{
    filter: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      spb: null,
      colors:[
        'light-primary',
        'light-success',
        'light-info',
        'light-warning',
        'light-danger',
      ],
      dataurl:"/spb",
      baseroute:"spb",
      fields: [
        { key: 'vendor.name', label: 'Vendor', sortable: true },
        { key: 'contract_number', label: 'No Kontrak', sortable: true },
        { key: 'date', label: 'Tanggal', sortable: true },
        { key: 'nominal', label: 'Nominal Kontrak', sortable: true },
        { key: 'index', label: 'Tagihan Ke', sortable: true },
        { key: 'month_index', label: 'Bulan Tagihan Terakhir', sortable: true },
        { key: 'total', label: 'Total Tagihan', sortable: true },
        { key: 'billed', label: 'Status', sortable: true },
      ],
    }
  },
  // created(){
  //   this.filter['between[periode]'] = null
  // },
  methods:{
    getNominal(data){
      var nominal = 0
      var duration = 0
      if(data.items){
        data.items.forEach(item => {
          if(duration < item.duration)
            duration += parseInt(item.duration)
            
          if(data.vehicles){
            data.vehicles.forEach(vehicle => {
              if(vehicle.variant_id == item.variant_id){
                nominal += parseInt(item.price)
              }
            })
          }
        })
      }
      return nominal*duration
    },
    viewForm(data){
      this.$router.push({ name: this.baseroute+'-detail',params : { id: data.item.id} })
    },
    getBillCount(data){
      var count = 0
      var detail = 0
      if(data.items){
        data.items.forEach(item => {if(count < item.duration){count += parseInt(item.duration)}})
      }
      if(data.billings){
        data.billings.forEach(bill => {
          if(bill.details){
            detail += bill.details.length
          }
        })
      }
      return detail+"/"+count+" Bulan"
    },
    getLastMonthBill(data){
      var lastmonth = 0
      if(data.billings){
        data.billings.forEach(bill => {
          if(bill.details){
            bill.details.forEach(detail =>{
              if(parseInt(lastmonth) < parseInt(detail.year+""+detail.month))
                lastmonth = detail.year+""+detail.month
            })
            
          }
        })
      }
      return lastmonth?moment(lastmonth).format("MMMM YYYY"):"-"
    },
    getTotalTagihan(data){
      var total_price = 0
      var total_bill = 0
      var duration = 0
      if(data.billings){
        data.billings.forEach(bill => {
          bill.details.forEach(detail =>{
            total_bill += parseInt(detail.amount)
          })
        })
      }
      if(data.items){
        data.items.forEach(item => {
          if(duration < item.duration)
            duration += parseInt(item.duration)

          if(data.vehicles){
            data.vehicles.forEach(vehicle => {
              if(vehicle.variant_id == item.variant_id){
                total_price += parseInt(item.price)
              }
            })
          }
        })
      }
      return new Intl.NumberFormat().format(total_bill)+"/"+new Intl.NumberFormat().format(total_price*duration)
    },
    getStatus(data){
      var total_price = 0
      var total_bill = 0
      var duration = 0
      if(data.billings){
        data.billings.forEach(bill => {
          bill.details.forEach(detail =>{
            total_bill = detail.amount
          })
        })
      }
      if(data.items){
        data.items.forEach(item => {
          if(duration < item.duration)
            duration += parseInt(item.duration)
            
          if(data.vehicles){
            data.vehicles.forEach(vehicle => {
              if(vehicle.variant_id == item.variant_id){
                total_price += parseInt(item.price)
              }
            })
          }
        })
      }
      return (total_bill===(total_price*duration))?'Lunas':'Proses'
    }
  }
}
</script>
