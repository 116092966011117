<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>
        Monitoring SKKO 
      </b-card-title>
      <b-card-text class="mr-25 mb-0">
        <!-- View All -->
        <b-spinner v-if="!skko" small variant="primary" />
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <base-table
        ref="basetable"
        :dataurl="dataurl"
        :fields="fields"
        :is-edit="false"
        :is-delete="false"
        :is-add="false"
        :is-view="false"
        :filter="filter"
        @showData="skko=true"
      >
        <template #cell(nominal)="data">
          Rp {{data.item.nominal|numFormat}}
        </template>
        <template #cell(remnant_skko)="data">
          Rp {{(data.item.nominal-getUsedPayment(data.item))|numFormat}}
        </template>
        <template #cell(used_skko)="data">
          Rp {{getUsedPayment(data.item)|numFormat}}
        </template>
      </base-table>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BSpinner
} from 'bootstrap-vue'
import BaseTable from '@/views/base/BaseTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BSpinner,
    BaseTable
  },
  props:{
    filter: {
      type: Array,
      default() {
        return []
      },
    },
  },
  // created(){
  //   this.filter['between[periode]'] = null
  // },
  data() {
    return {
      skko: null,
      colors:[
        'light-primary',
        'light-success',
        'light-info',
        'light-warning',
        'light-danger',
      ],
      dataurl:"/skko",
      fields: [
        { key: 'number', label: 'Code', sortable: true },
        { key: 'vendor.name', label: 'Vendor', sortable: true },
        { key: 'date_start', label: 'Periode Awal', sortable: true },
        { key: 'date_end', label: 'Periode Akhir', sortable: true },
        { key: 'year', label: 'Tahun', sortable: true },
        { key: 'nominal', label: 'Nominal', sortable: true },
        { key: 'used_skko', label: 'Digunakan', sortable: true },
        { key: 'remnant_skko', label: 'Sisa', sortable: true },
        // { key: 'description', label: 'Keterangan', sortable: true },
        // { key: 'status', label: 'Status', sortable: true }
      ],

    }
  },
  methods:{
    getUsedPayment(data){
      var total_price = 0
      if(data.spbs){
        var spbs = data.spbs
        spbs.forEach(spb => {
          if(spb.billings){
            var billings = spb.billings
            billings.forEach(billing => {
              if(billing.details){
                var details = billing.details
                details.forEach(detail => {
                  if(detail.status == 'paid'){
                    total_price += (parseInt(detail.amount)+(parseInt(detail.amount)*0.1))
                  }
                })
              }
            })
          }
        })
      }
      return total_price
    }
  }
}
</script>
